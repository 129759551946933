/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Header from "./header"
import Footer from "./footer"
import Share from "./share"
import "./layout.css"
import "./fonts.css"

const Main = styled.main`
  max-width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 260px);
  padding: 1rem;
`
const Overflow = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
`
class Layout extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		return (
			<Overflow>
				<Header location={this.props.location} />
				<div
					style={{
						margin: "0 auto",
						padding: "0",
						paddingTop: 128,
					}}
				>
					<Main>{this.props.children}</Main>
					<Share  
						socialConfig={{
							twitterHandle:"@GLOBART_Academy",
							config: {
								url: `${"https://www.globart-academy.at"}${this.props.location}`,
								title: "GLOBART Academy",
							},
						}}
						tags="GLOBART Academy 2020 MACHT"
					/>
					<Footer />
				</div>
			</Overflow>
		)
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
