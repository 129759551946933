import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import React from "react"
import Menu from "./menu"
import { StaticQuery, graphql } from "gatsby"
const LogoContainer = styled.h1`
  margin: 0;
  background: white;
  @media only screen and (max-width: 1080px) {
    display: flex;
    justify-content: center;
  }
`
const Logo = styled.img`
  width: 250px;
  background:none;
  @media only screen and (max-width: 1180px) {
    width: 200px;
    margin-bottom: 0;
  }
`
const StyledHeader = styled.header`
	/* transform: translateY(${props => props.top}px) ;  */
	position: ${props => (props.index ? props.position : "fixed")};
	top: ${props => (props.index ? props.top + "px" : "0")} ;
	display: flex;
	width:100vw;
	max-width: ${props => (props.index ? "auto" : "auto")};
	margin: 0 auto;
	justify-content: space-between;
	padding: 0 1rem 1rem 1rem;
	left: 0;
	right: 0;
	z-index: 10;
		max-height: 6.5rem;
		background: white;
	border-bottom: 2px solid black;
	@media only screen and (max-width: 1180px) {
		top: ${props => (props.index ? props.topmobile + "px" : "0")} ;
		justify-content: center;
		flex-direction: column;
		max-height: none;
		margin: 0;
		padding-bottom: 0;
	}

`
class Header extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const  data  = this.props.data.prismic.allHomepages.edges[0].node
		// add more props here as needed
		return (
			<StyledHeader>
				<LogoContainer>
					<Link to="/">
						<Logo
							src={data.logo.small.url}
							alt={data.title.text}
						/>
					</Link>
				</LogoContainer>
				<Menu location={this.props.location} />
			</StyledHeader>
		)
	}
}

export default props => (
	<StaticQuery
		query={graphql`
      query HeadingQuery {
        prismic {
          allHomepages {
            edges {
              node {
                logo
				title

              }
            }
          }
        }
      }
    `}
		render={data => <Header data={data} {...props} />}
	/>
)
